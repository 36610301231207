import React from 'react'
import { graphql } from 'gatsby'
import { useFilterContext } from '../context/filterContext'
import { Filters, Layout, Pagination, ProductListing, Seo } from '../components'

export default function Products({ pageContext: { currentPage } }) {
  const { filteredProducts } = useFilterContext()
  const productPages = Math.ceil(filteredProducts.length / 8)

  const isFirst = currentPage === 1
  const isLast = currentPage === productPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  const getPaginatedData = () => {
    const productsPerPage = 8
    const startIndex = currentPage * productsPerPage - productsPerPage
    const endIndex = startIndex + productsPerPage
    return filteredProducts.slice(startIndex, endIndex)
  }

  return (
    <Layout>
      <Seo title="Shop" />
      <Filters />
      <ProductListing products={getPaginatedData()} />
      <Pagination
        productPages={productPages}
        currentPage={currentPage}
        isFirst={isFirst}
        isLast={isLast}
        prevPage={prevPage}
        nextPage={nextPage}
        pageLink="shop"
      />
    </Layout>
  )
}

// export const query = graphql`
//   query productsQuery($skip: Int, $limit: Int) {
//     products: allShopifyProduct(sort: { fields: publishedAt, order: DESC }, limit: $limit, skip: $skip) {
//       nodes {
//         ...ProductCard
//       }
//       pageInfo {
//         pageCount
//       }
//     }
//   }
// `
